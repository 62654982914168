import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import { GlobalDispatchContext } from "../../context/GlobalContextProvider"

export default ({ data }) => {
  const { language, header, footer } = data.strapi.homepages[0]

  const dispatch = useContext(GlobalDispatchContext)
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language })
  })

  return (
    <Layout header={header} footer={footer}>
      <SEO title="404" />
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-12 text-center">
            <h2>Page not found</h2>
          </div>
          <div className="col-12 text-center">
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    strapi {
      homepages(where: { language: "en" }) {
        language
        header {
          logo {
            url
          }
          links {
            label
            slug
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
      }
    }
  }
`
